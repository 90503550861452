define(['zepto'], function ($) {
    'use strict';

    function activateBySpaceButton(evt) {
        if (evt.key === ' ') {
            evt.preventDefault();
            evt.stopPropagation();
            $(evt.target).click();
        }
    }

    function activateByEnterButton(evt, target = evt.target) {
        if (evt.key === 'Enter') {
            evt.preventDefault();
            evt.stopPropagation();
            target.click();
        }
    }

    function activateBySpaceOrEnterButton(evt) {
        activateByEnterButton(evt);
        activateBySpaceButton(evt);
    }

    return {
        activateByEnterButton,
        activateBySpaceButton,
        activateBySpaceOrEnterButton
    };
});
