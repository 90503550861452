define([
    'componentsCore/utils/accessibility/tabbable/tabbable',
    'componentsCore/utils/accessibility/keyboardInteractions/keyboardInteractions'
], function (
    tabbable,
    keyboardInteractions
) {
    'use strict';

    return {
        addFocusRingClass: element => {
            element.classList.add('focus-ring');
        },
        getTabbaleElements: tabbable.getTabbableElements,
        keyboardInteractions
    };
});
